'use client' // Error boundaries must be Client Components

import { useCallback, startTransition } from 'react'

import { CWButton } from '@features/common.components/ComponentsWeb/Button'
import { Logo } from '@features/common.components/Header/Logo'
import Image from 'next/image'
import { useRouter } from 'next/navigation'
import useTranslation from 'next-translate/useTranslation'

export interface ErrorPageProps {
  reset: () => void
}

export const ErrorPage: React.FC<ErrorPageProps> = ({ reset }) => {
  const { t } = useTranslation('common')

  const router = useRouter()

  // `startTransition()` ensures that both client-side and server-side components are re-rendered together
  // full solution explanation: https://github.com/vercel/next.js/issues/63369#issuecomment-2211698627
  const handleRetry = useCallback(() => {
    startTransition(() => {
      reset()
      router.refresh()
    })
  }, [reset, router])

  return (
    <div
      className="w-screen h-screen relative flex items-center justify-center"
      data-testid="error-page"
    >
      <div className="absolute top-0 bg-white w-full flex items-center justify-center py-5 z-10">
        <Logo />
      </div>

      <div className="border border-neutral-subtle bg-white bg-opacity-75 rounded-md py-6 flex flex-col items-center w-full mx-6 max-w-96 z-10">
        <h1 className="type-h-page-sm mb-1.5">{t`errorPage.title`}</h1>
        <p className="type-intro-sm text-neutral-subtle">{t`errorPage.description1`}</p>
        <p className="type-intro-sm text-neutral-subtle">{t`errorPage.description2`}</p>
        <CWButton
          className="mt-6"
          onClick={handleRetry}
          variant="primary"
        >{t`errorPage.buttonLabel`}</CWButton>
      </div>

      <Image
        alt={t`errorPage.backgroundImageAlt`}
        className="object-cover w-full h-full absolute top-0 left-0"
        src="/images/ErrorPageBackground.svg"
        fill
        priority
      />
    </div>
  )
}
