const staticsHoc = require('hoist-non-react-statics')

/**
 * @type {import('next-translate').I18nConfig}
 */
module.exports.i18nConfig = {
  logger: () => {},
  locales: ['en'],
  defaultLocale: 'en',
  localeDetection: true,
  loadLocaleFrom: (lang, ns) => {
    // eslint-disable-next-line sonarjs/no-small-switch
    switch (ns) {
      case 'common':
        return import(`@features/common.locales/${lang}/index.ts`).then((m) => {
          return m.default
        })
      case 'faucet':
        return import(`@features/faucet.locales/${lang}/index.ts`).then((m) => {
          return m.default
        })
      case 'algolia':
        return import(`@features/algolia.locales/${lang}/index.ts`).then(
          (m) => {
            return m.default
          },
        )
      case 'playground':
        return import(`@features/playground.locales/${lang}/index.ts`).then(
          (m) => {
            return m.default
          },
        )
      case 'quickstart':
        return import(`@features/quickstart.locales/${lang}/index.ts`).then(
          (m) => {
            return m.default
          },
        )
      case 'content':
        return import(`@features/content.locales/${lang}/index.ts`).then(
          (m) => {
            return m.default
          },
        )
      case 'documentation':
        return import(`@features/documentation.locales/${lang}/index.ts`).then(
          (m) => {
            return m.default
          },
        )
      case 'liquidity-wizard':
        return import(
          `@features/liquidity-wizard.locales/${lang}/index.ts`
        ).then((m) => {
          return m.default
        })
    }
  },
  pages: {
    '*': (props) => {
      const namespaces = ['common']

      /**
       * TODO: Improve the loading of namespaces for sub paths of (docs).
       *
       * Example: The not-found page will not need the playground / quickstart / documentation namespaces once the unified api references are merged.
       */
      if (props.pathname.startsWith('/(docs)')) {
        namespaces.push(
          'common',
          'algolia',
          'quickstart',
          'content',
          'documentation',
          'playground',
        )
      }

      if (props.pathname.startsWith('/(faucet)')) {
        namespaces.push('faucet')
      }

      if (props.pathname.startsWith('/(liquidity-wizard)')) {
        namespaces.push('liquidity-wizard')
      }

      return namespaces
    },
  },
  staticsHoc,
}
